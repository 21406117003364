import { styled } from 'styled-components';

const DesktopHeader = () => {
	return (
		<Style>
			<div className="logo-container">
				<img src="/blue-logo.svg" alt="" />
				<p>
					<a href="/">
						Prometa<span>Soft</span>
					</a>
				</p>
			</div>
			<ul className="nav-links">
				<li>
					<a href="/about">About</a>
				</li>
				<li>
					<a href="/#services">Services</a>
				</li>
				<li>
					<a href="/#projects">Projects</a>
				</li>
				<li>
					<a
						href="https://blog.prometasoft.org"
						target="_blank"
						rel="noreferrer"
					>
						Blog
					</a>
				</li>
				<li>
					<a href="/get-in-touch" className="get-in-touch-btn">
						<span>Get In Touch</span>
					</a>
				</li>
			</ul>
		</Style>
	);
};

export default DesktopHeader;

const Style = styled.header`
	display: none;
	justify-content: space-between;
	align-items: flex-start;
	padding: 40px 70px 0 20px;
	height: 15vh;

	max-width: 1280px;
	margin-left: auto;
	margin-right: auto;

	.logo-container {
		display: flex;
		gap: 5px;

		& img {
			height: 20px;
		}

		& a {
			text-decoration: none;
			color: #003060;
		}

		& p {
			font-weight: 700;
			font-size: 20px;
			color: #003060;

			& span {
				font-style: italic;
			}
		}
	}

	& .nav-links li {
		display: inline-block;
		margin-left: 20px;
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 21px;
		color: #1f1f1f;
		& a {
			text-decoration: none;
			color: #1f1f1f;
		}
	}

	& .get-in-touch-btn {
		position: relative;
		text-decoration: none;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		width: 130px;
		height: 37px;
		background: #003060;
		cursor: pointer;
		border: transparent;
		font-weight: 600;
		font-size: 16px;
		line-height: 20px;
		color: #eef4ed !important;

		// &::before {
		//   content: "";
		//   position: relative;
		//   color: white;
		//   top: 0;
		//   left: 0;
		//   background-color: red;
		//   // z-index: -1;
		//   height: 100%;
		//   width: 100%;
		//   transition: all 400ms ease;
		// }

		// &:hover {
		// }
	}

	@media (min-width: 768px) {
		display: flex;
	}
`;
