import DesktopHeader from './DesktopHeader';
import MobileHeader from './MobileHeader';

const Header = () => {
	return (
		<div>
			<DesktopHeader />
			<MobileHeader />
		</div>
	);
};

export default Header;
