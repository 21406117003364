import { styled } from 'styled-components';

const Services = () => {
	// const contents = document.querySelectorAll('.service-card-container > *');
	// const xx = ['x1', 'x2', 'x3'];

	// contents.forEach((ele, index) => {
	// 	if (index < 3) {
	// 		ele.classList.add('top-service', `${xx[index]}`);
	// 	} else {
	// 		ele.classList.add('down-service', `${xx.reverse()[index % 3]}`);
	// 	}
	// });

	return (
		<Style id="services">
			<div className="section-title">
				<h2>Services</h2>
				<p>We provide valuable solutions</p>
			</div>

			<div className="service-card-container">
				<div className="top-service x1">
					<div className="card">
						<img src="/services-icon.svg" alt="" />
						<h3>Content Creation</h3>
						<p>
							The words we use shape our lives and businesses, and the right
							content is everything. Our experienced content creators understand
							your business needs and create a voice that is unique to your
							brand.
						</p>
					</div>
				</div>

				<div className="top-service x2">
					<div className="card">
						<img src="/services-icon.svg" alt="" />
						<h3>Software Development</h3>
						<p>
							The fuel that most successful modern brands run on is software.
							PrometaSoft combines technical expertise, thorough planning,
							speed, and flawless execution to create reliable and engaging
							software that meets your needs. Desktop Mobile, Android, iOS,
							Windows, Mac OS, and Linux
						</p>
					</div>
				</div>
				<div className="top-service x3">
					<div className="card">
						<img src="/services-icon.svg" alt="" />
						<h3>Product Design</h3>
						<p>
							At PrometaSoft, design is at the heart of everything we do. We
							understand how to provide the ideal concept and package,
							displaying appealing interfaces and telling the right story to
							give your brand a significant competitive advantage. UI Design, UX
							Design, Product Packaging Brochures/Slides, Mockups/Renders, and
							Circuit Design.
						</p>
					</div>
				</div>

				<div className="down-service x3">
					<div className="card">
						<img src="/services-icon.svg" alt="" />
						<h3>Data Analysis, Research $ Consultancy</h3>
						<p>
							At PrometaSoft, we provide a full range of data analysis services
							as well as elite business consulting, assisting businesses in
							making quick, data-driven decisions in the constantly changing
							environment. Our data scientists are aware of the trends in your
							data and how to use them to boost productivity in your company.
						</p>
					</div>
				</div>
				<div className="down-service x2">
					<div className="card">
						<img src="/services-icon.svg" alt="" />
						<h3>Web Development</h3>
						<p>
							A strong website showcases your knowledge while enhancing your
							reputation as a reliable brand. One of our favorite playgrounds is
							the internet. We are able to translate your business needs into
							fantastic websites and applications. At PrometaSoft, we gauge the
							effectiveness of our websites and web applications and compare
							deployment to these metrics.
						</p>
					</div>
				</div>
				<div className="down-service x1">
					<div className="card">
						<img src="/services-icon.svg" alt="" />
						<h3>Branding</h3>
						<p>
							Branding is crucial! It conveys the essence of your company and
							sets the tone for interactions with clients and customers. Our
							brand team is experienced in developing a strong brand that tells
							your story and leaves a distinct, long-lasting impression of your
							company in the minds of consumers.
						</p>
					</div>
				</div>
			</div>
		</Style>
	);
};

export default Services;

const Style = styled.div`
	max-width: 1280px;
	margin-left: auto;
	margin-right: auto;

	& .section-title {
		text-align: center;
		padding-bottom: 100px;

		& h2 {
			color: #002040;
			font-weight: 700;
			font-size: 42px;
		}
	}

	& .service-card-container {
		display: grid;
		grid-template-columns: 1fr;
		grid-gap: 20px;
		padding: 20px;

		& .card {
			background-color: #004080;
			padding: 50px 30px;
			color: #eef4ed;
			line-height: 20px;
			font-size: 16px;
			height: 100%;

			& p {
				text-align: justify;
			}

			& h3 {
				font-weight: 700;
				font-size: 24px;
				color: white;
				line-height: 33px;
				margin-bottom: 20px;
			}
		}

		@media (min-width: 768px) {
			grid-template-columns: repeat(3, 1fr);
			padding-bottom: 100px;
			padding-left: 10%;
			padding-right: 10%;

			& .top-service {
				display: flex;
				flex-direction: column;
				justify-content: flex-end;
				&.x1 {
					padding-top: 100px;
				}
				&.x2 {
					padding-top: 50px;
				}
				&.x3 {
					padding-top: 0;
				}
			}

			& .down-service {
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				&.x1 {
					padding-bottom: 100px;
				}
				&.x2 {
					padding-bottom: 50px;
				}
				&.x3 {
					padding-bottom: 0;
				}
			}
		}
	}
`;
