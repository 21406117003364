import Header from './Header';
import { styled } from 'styled-components';
import Marquee from 'react-fast-marquee';
import Chip from './Chip';

const Hero = () => {
	return (
		<Style>
			<div>
				<p>
					<Marquee style={{ overflow: 'hidden' }}>Prometasoft</Marquee>
				</p>
			</div>
			<div>
				<Header />

				<div className="landing-2">
					<div className="tagline">
						<h1>
							Turning ideas into <span>innovative</span> products that help
							people
							<span>solve problems</span>
						</h1>
					</div>
					<div className="chip-wrapper">
						<div>
							<div>
								<a href="/get-in-touch" className="get-in-touch">
									<span>Get In Touch</span>
								</a>
								<div className="box"></div>
							</div>
						</div>
						<div className="box-unnecessary-container">
							<div className="box-unnecessary"></div>
						</div>
						<div>
							<Chip />
						</div>
					</div>
				</div>
			</div>
		</Style>
	);
};

export default Hero;

const Style = styled.section`
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 1fr;
	position: relative;

	& > div:first-child {
		grid-column: 1 / -1;
		grid-row: 1 / -1;
		color: #0020400a;
		position: relative;
		overflow: hidden;

		& p {
			font-size: 270px;
			font-weight: 700;
		}
	}

	& > div:last-child {
		grid-column: 1 / -1;
		grid-row: 1 / -1;
		display: relative;
		z-index: 1;
	}

	& .landing-2 {
		max-width: 1280px;
		margin-left: auto;
		margin-right: auto;

		& h1 {
			font-size: 32px;
			font-weight: 600;
			color: #002040;
			padding: 30px;
			margin-top: 96px;
			margin-bottom: 50px;

			& span {
				color: #55aaff;
			}
		}

		.chip-wrapper {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			grid-template-rows: auto auto;
			grid-gap: 20px;

			& .box {
				width: 58px;
				height: 58px;
				background-color: #80bfff;
			}

			& .box-unnecessary-container {
				& .box-unnecessary {
					width: 58px;
					height: 100px;
					background-color: #004080;
					float: right;
				}
			}

			& > div:last-child {
				grid-column: 1 / -1;
			}

			@media (min-width: 768px) {
				display: flex;
				justify-content: space-between;

				& > div:first-child {
					display: flex;
					flex: 1;
					justify-content: center;
					align-items: center;
					order: 1;
				}

				& .box-unnecessary-container {
					order: 3;
					flex: 1;
					display: flex;
					align-items: flex-end;
					justify-content: flex-end;

					& .box-unnecessary {
						display: none;
						width: 130px;
						height: 140px;
					}
				}

				& > div:last-child {
					// grid-column: 2 / span 1;
					order: 1;
					flex: 1;
				}
			}
		}
	}

	& .get-in-touch {
		background-color: #002040;
		text-decoration: none;
		color: white;
		width: 120px;
		height: 120px;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 26px;
		font-weight: 700;
		font-size: 21px;
		text-align: center;
	}

	@media (min-width: 768px) {
		& h1 {
			font-size: 36px;
			line-height: 42px;
			max-width: 600px;
			// margin-left: 100px;
		}
		& .landing-2 {
			margin-top: 50px;

			.chip-wrapper {
				grid-template-columns: repeat(3, 1fr);
			}
		}
	}
`;
