import { Helmet } from 'react-helmet';

const Layout = ({ title, children }: { title?: string; children?: any }) => {
	return (
		<>
			<Helmet>
				<title>{title ? `${title} | ` : ''}Solving Problems</title>
			</Helmet>
			{children}
		</>
	);
};

export default Layout;
