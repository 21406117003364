import { styled } from 'styled-components';
import Marquee from 'react-fast-marquee';
import { links } from '../links';

const WhoWeAre = () => {
	return (
		<Style>
			<div>
				<Marquee className="">PrometaSoft</Marquee>
			</div>
			<div>
				<div className="a">
					<ul className="social-media">
						<li>
							<a href={links.linkedin} target="_blank" rel="noreferrer">
								LinkedIn
								<span className="material-symbols-outlined">north_east</span>
							</a>
						</li>
						<li>
							<a href={links.twitter} target="_blank" rel="noreferrer">
								Twitter
								<span className="material-symbols-outlined">north_east</span>
							</a>
						</li>
					</ul>

					<div className="quote">
						<img src="/quote.svg" />
						<div>
							<p>
								The best way to predict the future is
								<br /> to create it
							</p>
							<p className="author">Alan Kay (born 1940)</p>
						</div>
					</div>
				</div>

				<div className="who-we-are">
					<div>
						<div className="box-unnecessary"></div>
					</div>
					<div>
						<h2>Who we are</h2>
						<div className="description">
							<p>
								Prometasoft is a digital product innovation agency that offers a
								range of services to help businesses thrive in today&apos;s
								fast-paced digital world. We believe in the power of creativity,
								technology, and data to drive results, and offer services like
								content creation, software development, product design, data
								analysis, research & consultancy, web development, and branding.
							</p>

							<p className="xx">ABOUT US</p>
						</div>
						<div></div>
						<a
							href="https://mr3kcf42hy6.typeform.com/to/lTdIlMwu"
							target="_blank"
							className="get-in-touch"
							rel="noreferrer"
						>
							Get In Touch
						</a>
					</div>
				</div>
			</div>
		</Style>
	);
};

export default WhoWeAre;

const Style = styled.section`
	padding-top: 50px;
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 1fr;
	width: 100%;

	& > div:first-child {
		grid-column: 1 / -1;
		grid-row: 1 / -1;
		color: #e4ece6;
		font-weight: 700;
		position: relative;
		z-index: -1;
		& .dd {
			font-size: 520px;
			overflow: hidden;
		}
	}

	& > div:last-child {
		grid-column: 1 / -1;
		grid-row: 1 / -1;

		& .social-media {
			text-align: center;
			margin-bottom: 45px;

			& li {
				display: inline-block;

				&:first-child {
					margin-right: 20px;
				}

				& a {
					color: #002040;
					font-weight: 700;
					font-size: 24px;
					text-decoration: none;

					& span {
						color: #00cecb;
						font-weight: 700;
					}
				}
			}
		}

		& .quote {
			position: relative;
			padding: 20px;
			margin-left: auto;
			margin-right: auto;
			width: fit-content;
			font-size: 16px;
			line-height: 20px;

			& img {
				position: absolute;
				top: -3px;
				left: 3px;
			}

			& .author {
				color: #c3c3c3;
				font-style: italic;
				font-size: 14px;
				line-height: 18px;
			}
		}

		& .who-we-are {
			margin-top: 110px;
			display: grid;
			grid-template-columns: auto 1fr;
			column-gap: 20px;
			margin-bottom: 50px;

			max-width: 1280px;
			margin-left: auto;
			margin-right: auto;

			& h2 {
				color: #002040;
				font-weight: 700;
				font-size: 42px;
				margin-bottom: 50px;
			}

			& .description {
				font-size: 21px;
				color: #636363;
				text-align: justify;
				padding-right: 20px;
				line-height: 28px;
			}

			& .xx {
				color: black;
				line-height: 24px;
				font-weight: 600;
				font-size: 18px;
				margin-top: 30px;
				margin-bottom: 50px;
			}

			& .box-unnecessary {
				height: 150px;
				width: 50px;
				background-color: #004080;
			}

			@media (min-width: 768px) {
				& > div:last-child {
					display: grid;
					grid-template-columns: 1fr 1fr 0.5fr;
					grid-gap: 20px;
				}

				& .box-unnecessary {
					display: none;
				}
			}
		}

		& .get-in-touch {
			text-decoration: none;
			background-color: #002040;
			color: white;
			width: 120px;
			height: 120px;
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 26px;
			font-weight: 700;
			font-size: 21px;
			text-align: center;
		}
	}

	@media (min-width: 768px) {
		& .box-unnecessary {
			display: none;
		}

		& .a {
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 80%;
			margin: auto;

			max-width: 1280px;
			margin-left: auto;
			margin-right: auto;

			& .quote {
				margin-right: 0;
			}
		}
	}
`;
