import { styled } from 'styled-components';

const Chip = () => {
	return (
		<Style>
			<div>
				<img src="/hero-image.svg" alt="" />
			</div>
			<div>
				<img src="/chip.svg" alt="" />
			</div>
		</Style>
	);
};

export default Chip;

const Style = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 1fr;

	& img {
		max-width: 100%;
		height: auto;
	}

	& > div:first-child {
		grid-column: 1 / -1;
		grid-row: 1 / -1;

		display: grid;
		grid-template-columns: 2fr 1fr 1fr;

		& img {
			grid-column: 1 / span 2;
		}
	}

	& > div:last-child {
		grid-column: 1 / -1;
		grid-row: 1 / -1;

		display: grid;
		grid-template-columns: 2fr 1fr 1fr;

		& img {
			grid-column: 2 / -1;
		}
	}
`;
