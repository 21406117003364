import { styled } from 'styled-components';

const OurProject = () => {
	return (
		<Style id="projects">
			<div className="section-title">
				<h2>Our Project</h2>
				<p>
					Cutting-edge solutions designed to empower businesses to achieve their
					maximum potential.
				</p>
			</div>
			<div className="project">
				<div>
					<div className="info">
						<div>
							<div>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="186"
									height="55"
									viewBox="0 0 186 55"
									fill="none"
								>
									<path
										d="M54.7353 28.1915H57.3972C58.2275 28.1915 58.8869 27.9717 59.3753 27.5321C59.8637 27.0763 60.1079 26.4739 60.1079 25.725C60.1079 24.9598 59.8637 24.3574 59.3753 23.9178C58.9031 23.4619 58.2438 23.234 57.3972 23.234H54.7353V28.1915ZM57.8123 31.0976H54.7109V37.618H51.3408V20.3035H57.8123C59.5055 20.3035 60.8731 20.8082 61.9151 21.8176C62.9733 22.827 63.5024 24.1213 63.5024 25.7005C63.5024 27.2798 62.9733 28.5741 61.9151 29.5835C60.8731 30.5929 59.5055 31.0976 57.8123 31.0976Z"
										fill="#DAFFFF"
									/>
									<path
										d="M72.9467 25.554V28.8264C72.6211 28.7613 72.2955 28.7287 71.9699 28.7287C71.0419 28.7287 70.293 28.9974 69.7231 29.5346C69.1533 30.0556 68.8684 30.9185 68.8684 32.1233V37.618H65.6204V25.6029H68.7707V27.3856C69.3568 26.132 70.4965 25.5052 72.1897 25.5052C72.3687 25.5052 72.6211 25.5214 72.9467 25.554Z"
										fill="#DAFFFF"
									/>
									<path
										d="M78.3357 34.1258C78.9381 34.7282 79.6626 35.0294 80.5092 35.0294C81.3558 35.0294 82.0722 34.7282 82.6583 34.1258C83.2607 33.5234 83.5618 32.685 83.5618 31.6104C83.5618 30.5359 83.2607 29.6974 82.6583 29.0951C82.0722 28.4927 81.3558 28.1915 80.5092 28.1915C79.6626 28.1915 78.9381 28.4927 78.3357 29.0951C77.7496 29.6974 77.4566 30.5359 77.4566 31.6104C77.4566 32.685 77.7496 33.5234 78.3357 34.1258ZM75.9913 27.0437C77.1961 25.8389 78.702 25.2365 80.5092 25.2365C82.3164 25.2365 83.8142 25.8389 85.0027 27.0437C86.2075 28.2485 86.8098 29.7707 86.8098 31.6104C86.8098 33.4501 86.2075 34.9724 85.0027 36.1772C83.8142 37.3819 82.3164 37.9843 80.5092 37.9843C78.702 37.9843 77.1961 37.3819 75.9913 36.1772C74.8028 34.9724 74.2086 33.4501 74.2086 31.6104C74.2086 29.7707 74.8028 28.2485 75.9913 27.0437Z"
										fill="#DAFFFF"
									/>
									<path
										d="M92.6217 37.618H89.3737V25.6029H92.4751V27.0681C92.7845 26.5146 93.2729 26.075 93.9404 25.7494C94.6079 25.4238 95.2917 25.261 95.9918 25.261C97.7338 25.261 98.9223 25.9285 99.5572 27.2635C100.42 25.9285 101.69 25.261 103.367 25.261C104.588 25.261 105.614 25.6436 106.444 26.4087C107.291 27.1739 107.714 28.3136 107.714 29.8277V37.618H104.564V30.4871C104.564 29.8033 104.384 29.2579 104.026 28.8508C103.684 28.4276 103.155 28.2159 102.439 28.2159C101.771 28.2159 101.234 28.4438 100.827 28.8997C100.42 29.3556 100.217 29.9172 100.217 30.5847V37.618H96.993V30.4871C96.993 29.8033 96.8139 29.2579 96.4558 28.8508C96.0976 28.4276 95.5685 28.2159 94.8684 28.2159C94.1846 28.2159 93.6392 28.4438 93.2322 28.8997C92.8252 29.3393 92.6217 29.901 92.6217 30.5847V37.618Z"
										fill="#DAFFFF"
									/>
									<path
										d="M113.373 30.2428H118.746C118.713 29.5753 118.469 29.0137 118.013 28.5578C117.574 28.1019 116.922 27.874 116.06 27.874C115.278 27.874 114.643 28.1182 114.155 28.6066C113.666 29.0951 113.406 29.6405 113.373 30.2428ZM119.063 33.3687L121.774 34.1746C121.448 35.2817 120.805 36.1934 119.845 36.9098C118.901 37.6261 117.72 37.9843 116.304 37.9843C114.578 37.9843 113.113 37.4064 111.908 36.2504C110.703 35.0782 110.101 33.5153 110.101 31.5616C110.101 29.7056 110.687 28.1915 111.859 27.0193C113.031 25.8308 114.415 25.2365 116.011 25.2365C117.867 25.2365 119.316 25.7901 120.358 26.8972C121.416 28.0043 121.945 29.5265 121.945 31.4639C121.945 31.5941 121.937 31.7407 121.921 31.9035C121.921 32.0663 121.921 32.1965 121.921 32.2942L121.896 32.4652H113.3C113.333 33.2466 113.642 33.8979 114.228 34.4188C114.814 34.9398 115.514 35.2003 116.328 35.2003C117.712 35.2003 118.624 34.5898 119.063 33.3687Z"
										fill="#DAFFFF"
									/>
									<path
										d="M128.508 22.013V25.6029H130.925V28.4845H128.508V33.5153C128.508 34.02 128.622 34.3781 128.85 34.5898C129.078 34.8014 129.436 34.9073 129.924 34.9073C130.364 34.9073 130.698 34.8747 130.925 34.8096V37.4959C130.453 37.6913 129.843 37.7889 129.094 37.7889C127.922 37.7889 126.994 37.4633 126.31 36.8121C125.626 36.1446 125.284 35.2329 125.284 34.077V28.4845H123.111V25.6029H123.721C124.324 25.6029 124.78 25.4319 125.089 25.09C125.414 24.7318 125.577 24.2678 125.577 23.698V22.013H128.508Z"
										fill="#DAFFFF"
									/>
									<path
										d="M133.023 34.3456C133.023 33.3199 133.357 32.4977 134.025 31.8791C134.692 31.2604 135.555 30.8697 136.613 30.7068L139.568 30.2673C140.171 30.1859 140.472 29.901 140.472 29.4125C140.472 28.9567 140.293 28.5822 139.935 28.2892C139.593 27.9961 139.096 27.8496 138.445 27.8496C137.761 27.8496 137.216 28.0368 136.809 28.4113C136.418 28.7857 136.198 29.2497 136.149 29.8033L133.268 29.1927C133.382 28.1508 133.894 27.2309 134.806 26.4332C135.718 25.6354 136.923 25.2365 138.42 25.2365C140.211 25.2365 141.53 25.668 142.377 26.5309C143.223 27.3774 143.647 28.4683 143.647 29.8033V35.7132C143.647 36.4295 143.695 37.0645 143.793 37.618H140.814C140.732 37.2598 140.692 36.7795 140.692 36.1772C139.926 37.3657 138.746 37.9599 137.151 37.9599C135.913 37.9599 134.912 37.6017 134.147 36.8854C133.398 36.169 133.023 35.3224 133.023 34.3456ZM137.834 35.5422C138.6 35.5422 139.226 35.3306 139.715 34.9073C140.219 34.4677 140.472 33.7513 140.472 32.7582V32.221L137.761 32.6361C136.768 32.7826 136.271 33.2873 136.271 34.1502C136.271 34.541 136.41 34.8747 136.687 35.1515C136.963 35.412 137.346 35.5422 137.834 35.5422Z"
										fill="#DAFFFF"
									/>
									<path
										d="M156.225 37.618H145.333L148.996 20.3035H152.366L149.387 34.3944H156.909L156.225 37.618Z"
										fill="#DAFFFF"
									/>
									<path
										d="M165.087 28.2159C164.11 28.2159 163.337 28.6392 162.767 29.4858C162.197 30.3324 161.913 31.293 161.913 32.3675C161.913 33.1978 162.124 33.849 162.547 34.3212C162.971 34.7933 163.541 35.0294 164.257 35.0294C165.12 35.0294 165.852 34.6468 166.455 33.8816C167.074 33.1001 167.383 32.0663 167.383 30.7801C167.383 29.9824 167.179 29.3556 166.772 28.8997C166.365 28.4438 165.804 28.2159 165.087 28.2159ZM168.604 25.6029H171.754L169.776 34.9805C169.63 35.6806 169.556 36.4051 169.556 37.154C169.556 37.3168 169.564 37.4715 169.581 37.618H166.577C166.561 37.4878 166.553 37.3005 166.553 37.0563C166.553 36.8447 166.569 36.5435 166.601 36.1527C165.836 37.2761 164.762 37.8378 163.378 37.8378C161.945 37.8378 160.805 37.3331 159.959 36.3237C159.112 35.298 158.689 34.02 158.689 32.4896C158.689 30.4545 159.275 28.7532 160.447 27.3856C161.619 26.0017 163.077 25.3098 164.819 25.3098C165.812 25.3098 166.585 25.4807 167.139 25.8226C167.708 26.1645 168.099 26.5471 168.311 26.9704L168.604 25.6029Z"
										fill="#DAFFFF"
									/>
									<path
										d="M175.663 37.618H172.513L176.225 19.9372H179.424L178.032 26.5797C178.781 25.7494 179.856 25.3342 181.256 25.3342C182.754 25.3342 183.91 25.8064 184.724 26.7506C185.538 27.6949 185.945 28.8997 185.945 30.365C185.945 32.5628 185.342 34.37 184.138 35.7864C182.949 37.2028 181.451 37.9111 179.644 37.9111C178.765 37.9111 178 37.7157 177.349 37.325C176.697 36.9342 176.258 36.4702 176.03 35.9329L175.663 37.618ZM180.133 28.1915C179.221 28.1915 178.447 28.5822 177.813 29.3637C177.194 30.1452 176.885 31.1139 176.885 32.2698C176.885 33.1164 177.112 33.792 177.568 34.2967C178.024 34.7852 178.61 35.0294 179.327 35.0294C180.173 35.0294 180.938 34.6631 181.622 33.9304C182.322 33.1815 182.672 32.164 182.672 30.8778C182.672 30.0149 182.436 29.3556 181.964 28.8997C181.508 28.4276 180.898 28.1915 180.133 28.1915Z"
										fill="#DAFFFF"
									/>
									<path
										d="M0 20.8803H17.2487V37.2212C17.2487 46.7474 9.5262 54.4699 0 54.4699V20.8803Z"
										fill="#DAFFFF"
									/>
									<path
										d="M0 17.2495C0 7.7233 7.72251 0.000789656 17.2487 0.000789656V20.8803H0V17.2495Z"
										fill="#ECFFFF"
									/>
									<path
										d="M16.3405 20.8799C16.3405 30.4061 24.063 38.1286 33.5892 38.1286H40.8518V20.8799H16.3405Z"
										fill="#DAFFFF"
									/>
									<path
										d="M35.4034 4.53904C34.4006 4.53904 33.5877 3.72614 33.5877 2.72339V1.81565C33.5877 0.812896 32.7748 2.42248e-07 31.7721 1.98415e-07L27.233 0C26.2302 -4.38327e-08 25.4173 0.812897 25.4173 1.81565V2.72339C25.4173 3.72614 24.6044 4.53904 23.6016 4.53904H22.6901C21.6873 4.53904 20.8744 5.35194 20.8744 6.35469V10.8938C20.8744 11.8966 21.6873 12.7095 22.6901 12.7095H23.6016C24.6044 12.7095 25.4173 13.5224 25.4173 14.5251V15.4331C25.4173 16.4358 26.2302 17.2487 27.233 17.2487H31.7721C32.7748 17.2487 33.5877 16.4358 33.5877 15.4331V14.5251C33.5877 13.5224 34.4006 12.7095 35.4034 12.7095H36.3075C37.3102 12.7095 38.1231 11.8966 38.1231 10.8938V6.35469C38.1231 5.35194 37.3102 4.53904 36.3075 4.53904H35.4034Z"
										fill="#DAFFFF"
									/>
								</svg>
							</div>
							<p className="coming-soon">Coming Soon</p>
						</div>
						<p>
							PrometaLab is a comprehensive software solution designed to
							improve laboratory productivity, effectiveness, and efficiency. It
							offers a range of features that help medical laboratories cut
							time, support responsiveness, promote accuracy, manage complexity
							and change, and ease IT.
						</p>
					</div>
				</div>
			</div>
		</Style>
	);
};

export default OurProject;

const Style = styled.section`
	& .section-title {
		max-width: 1280px;
		margin-left: auto;
		margin-right: auto;
		padding: 20px;
		margin-bottom: 50px;

		& h2 {
			color: #002040;
			font-weight: 700;
			font-size: 42px;
		}
	}

	& .project {
		background-image: url('our-project-background.png');
		background-size: cover;
		padding: 20px;

		& > div {
			max-width: 1280px;
			margin-left: auto;
			margin-right: auto;
			display: grid;
			grid-template-columns: 1fr;

			@media (min-width: 768px) {
				grid-template-columns: 1fr 1fr;
			}
		}

		& .info {
			margin-top: 200px;
			background: rgba(0, 32, 64, 0.87);
			padding: 25px;
			display: inline-block;
			color: #eef4ed;
			line-height: normal;

			& > div {
				display: inline-flex;
				align-items: center;
			}

			& .coming-soon {
				display: inline-block;
				border-radius: 21px;
				padding: 7px 10px;
				background: rgba(15, 95, 174, 0.45);
				margin-left: 10px;
			}

			& > p {
				text-align: justify;
			}
		}
	}
`;
