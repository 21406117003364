import { styled } from 'styled-components';
import NotifyMe from './NotifyMe';
import { links } from '../links';

const Footer = () => {
	const year = new Date().getFullYear().toString();

	return (
		<Style id="footer">
			<div className="subscribe">
				<h2>Subscribe to our newsletter</h2>
				<NotifyMe />
			</div>
			<section className="footer-actions">
				<div className="links">
					<div className="logo">
						<img src="/logo.svg" alt="logo" />
						<p>
							Prometa<span className="italic">Soft</span>
						</p>
					</div>
				</div>
				<ul className="menu-links">
					<li>
						<a href={links.linkedin} target="_blank" rel="noreferrer">
							LinkedIn{' '}
							<span className="material-symbols-outlined">north_east</span>
						</a>
					</li>
					<li>
						<a href={links.twitter} target="_blank" rel="noreferrer">
							Twitter{' '}
							<span className="material-symbols-outlined"> north_east</span>
						</a>
					</li>
				</ul>
			</section>
			<div className="copyright">
				<p>Copyright &copy; {year} PrometaSoft</p>
			</div>
		</Style>
	);
};

export default Footer;

const Style = styled.footer`
	background-color: #002040;
	padding-top: 100px;

	& .subscribe {
		padding: 30px;

		max-width: 1280px;
		margin-left: auto;
		margin-right: auto;

		& h2 {
			color: #eef4ed;
			font-weight: 700;
			line-height: 51px;
			font-size: 39px;
		}

		@media (min-width: 768px) {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
	}

	& .footer-actions {
		display: flex;
		flex-direction: column;
		border-top: 1px solid #004080;
		padding: 20px;
		max-width: 1280px;
		margin-left: auto;
		margin-right: auto;

		@media (min-width: 768px) {
			flex-direction: row;
			justify-content: space-between;
			& ul {
				text-align: center;
			}
		}

		& .links {
			padding: 20px;

			& .logo {
				display: flex;
				margin-bottom: 30px;
				align-items: center;
				& img {
					width: 32px;
				}

				& p {
					margin-left: 10px;
					font-weight: 700;
					font-size: 21px;
					color: #e8f3fe;

					& .italic {
						font-style: italic;
					}
				}
			}
			& ul {
				margin-bottom: 50px;

				& li {
					display: inline-block;

					&:first-child {
						margin-right: 20px;
					}

					& a {
						color: #eef4ed;
						text-decoration: none;
						font-size: 16px;
						font-weight: 700;

						& span {
							color: #f2c643;
							font-size: 16px;
							font-weight: 600;
						}
					}
				}
			}
		}

		& ul.menu-links {
			padding: 20px;
			& li {
				display: block;
				margin-bottom: 42px;
				@media (min-width: 768px) {
					display: inline-block;
					margin-right: 42px;
				}
				& a {
					font-weight: 600;
					font-size: 21px;
					color: #eef4ed;
					text-decoration: none;
				}
			}
		}
	}

	& .copyright {
		text-align: center;
		color: #4797ff;
		font-size: 14px;
		padding: 20px;
	}
`;
