import Marquee from 'react-fast-marquee';
import Footer from './Footer';
import Header from './Header';
import { styled } from 'styled-components';
import Layout from './Layout';

const AboutPage = () => {
	return (
		<Layout title="About PrometaSoft">
			<Style>
				<Header />
				<div className="hero">
					<div>
						<p>
							<Marquee style={{ overflow: 'hidden' }}>About Us</Marquee>
						</p>
					</div>
					<div>
						<div>
							<p className="about">
								<span>About</span> PrometaSoft
							</p>
							<h1>
								We build links between companies and customers through{' '}
								<span>innovation.</span>
							</h1>
						</div>
					</div>
				</div>

				<div className="sect-2">
					<div>
						<p>
							<Marquee style={{ overflow: 'hidden' }}>PrometaSoft</Marquee>
						</p>
					</div>
					<div>
						<div className="scroll-holder">
							<div className="red"></div>
							<div className="blue"></div>
							<div className="green"></div>
						</div>
						<div>
							<div>
								<p>
									In today&apos;s fast-paced digital world, businesses need to
									stay ahead of the game to succeed. That&apos;s where
									Prometasoft comes in - a digital production innovation agency
									that offers a wide range of services to help your business
									thrive.
								</p>
								<p>
									At Prometasoft, we believe in the power of creativity,
									technology, and data to drive results. That&apos;s why we
									offer a suite of services designed to help businesses of all
									sizes stay ahead of the curve. From content creation to
									software development, product design, data analysis, research
									& consultancy, web development, and branding, we&apos;ve got
									you covered.
								</p>
								<p>
									Our team of experts are dedicated to helping you achieve your
									goals, no matter what they may be. Whether you&apos;re looking
									to create compelling content, develop innovative software, or
									make data-driven decisions, we&apos;ve got the skills and
									expertise to help you succeed.
								</p>
							</div>
						</div>
					</div>
				</div>

				<div className="dedication">
					<div>
						<img src="/about-us.png" alt="about us" />
					</div>
					<div>
						<p>
							We are dedicated to helping our clients thrive in the digital age
							and achieve their business objectives.
						</p>
					</div>
				</div>

				<div className="mission-vision">
					<div>
						<h2>
							Our <span>Mission</span>
						</h2>
						<p>
							Our mission is to empower businesses to achieve their goals
							through technology. We are committed to delivering customized
							software solutions that enable our clients to streamline their
							processes, increase efficiency, and ultimately drive growth and
							success.
						</p>
					</div>
					<div>
						<h2>
							Our <span>Vision</span>
						</h2>
						<p>
							At PrometaSoft, our vision is to be a leader in providing
							innovative and reliable software solutions to our clients
							worldwide. We strive to constantly evolve and adapt to new
							technologies and industry trends to deliver top-notch products and
							services that meet and exceed our clients&apos; expectations.
						</p>
					</div>
				</div>

				<div className="lets-talk">
					<div>
						<p className="talk">Ready to start a project? Let&apos;s talk</p>
					</div>
				</div>
				<Footer />
			</Style>
		</Layout>
	);
};

export default AboutPage;

const Style = styled.div`
	.hero {
		position: relative;
		height: 100%;
		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: 1fr;

		& > div:first-child {
			grid-column: 1 / -1;
			grid-row: 1 / -1;

			& p {
				font-size: 300px;
				font-weight: 700;
				color: #0020400a;
			}
		}

		& > div:last-child {
			position: relative;
			grid-column: 1 / -1;
			grid-row: 1 / -1;
			z-index: 1;
			height: 100%;
			width: 100%;

			& > div {
				padding: 20px;
				max-width: 1280px;
				margin-left: auto;
				margin-right: auto;

				& .about {
					color: #002040;
					font-weight: 600;
					font-size: 32px;
					line-height: 41.7px;
					margin-bottom: 50px;
					& span {
						color: #55aaff;
						font-style: italic;
					}
				}

				& h1 {
					font-weight: 600;
					font-size: 42px;
					line-height: 1.3;
					color: #002040;
					max-width: 767px;
					@media (min-width: 768px) {
						font-size: 55px;
						line-height: 72.11px;
					}
					& span {
						color: #55aaff;
					}
				}
			}
		}
	}

	.sect-2 {
		position: relative;
		margin-top: 50px;
		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: 1fr;

		& > div:first-child {
			grid-column: 1 / -1;
			grid-row: 1 / -1;
			overflow: hidden;

			& p {
				font-size: 580px;
				font-weight: 700;
				color: #e4ece6;
			}
		}

		& > div:last-child {
			grid-column: 1 / -1;
			grid-row: 1 / -1;
			z-index: 1;
			width: 100%;

			& .scroll-holder {
				display: none;
				justify-content: flex-end;

				@media (min-width: 768px) {
					display: flex;
				}
			}
			& .red {
				display: inline-block;
				height: 320px;
				width: 460px;
				background-color: red;
				background-image: url(/pic1.png);
				margin-right: 20px;
			}
			& .blue {
				display: inline-block;
				height: 320px;
				width: 460px;
				background-color: blue;
				background-image: url(/pic2.png);
				margin-right: 20px;
			}

			& .green {
				display: inline-block;
				height: 320px;
				width: 460px;
				background-color: green;
				background-image: url(/pic1.png);
			}

			& > div:last-child {
				display: grid;
				grid-template-columns: 1;
				width: 100%;
				max-width: 1280px;
				margin-left: auto;
				margin-right: auto;
				padding: 20px;

				@media (min-width: 768px) {
					grid-template-columns: 1fr 1fr 0.5fr;
				}

				& > div {
					grid-column: 2 / span 1;

					& p {
						text-align: justify;
						color: #002040;
						font-size: 20px;
						margin-bottom: 24px;
						font-weight: 600;
						line-height: 26.06px;
					}
				}
			}
		}
	}

	.dedication {
		display: grid;
		max-width: 1280px;
		margin-left: auto;
		margin-right: auto;
		padding: 20px;
		grid-template-columns: 1fr;
		grid-gap: 20px;
		font-size: 20px;
		font-weight: 600;
		line-height: 26.06px;
		text-align: justify;
		color: #6b6b6b;

		@media (min-width: 768px) {
			grid-template-columns: 1fr 1fr;
		}

		& img {
			width: 100%;
			margin: auto;
		}
	}

	.mission-vision {
		max-width: 1280px;
		margin-left: auto;
		margin-right: auto;
		padding: 20px;
		padding-top: 80px;
		padding-bottom: 200px;
		display: grid;
		grid-template-columns: 1fr;
		grid-gap: 50px;

		& p {
			font-size: 20px;
			font-weight: 600;
			line-height: 26.06px;
			text-align: justify;
			color: #002040;
		}

		& h2 {
			color: #002040;
			font-weight: 600;
			font-size: 53.1px;
			line-height: 69.19px;
			margin-bottom: 30px;

			& span {
				color: #55aaff;
				font-style: italic;
			}
		}

		@media (min-width: 768px) {
			grid-template-columns: 1fr 1fr;
		}
	}

	.lets-talk {
		background-color: #e8f3fe;
		padding: 50px;

		& > div {
			max-width: 1280px;
			margin-left: auto;
			margin-right: auto;
			padding: 20px;

			& p.talk {
				font-weight: 700;
				font-size: 64px;
				line-height: 83.39px;
				color: #002040;
				max-width: 661px;
			}
		}
	}
`;
