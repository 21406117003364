import { styled } from 'styled-components';
import Marquee from 'react-fast-marquee';
import Layout from './Layout';
import Footer from './Footer';
import Header from './Header';
import { useState } from 'react';
import { baseuri } from '../links';
import swal from 'sweetalert';

const GetInTouch = () => {
	const [state, setState] = useState({
		name: '',
		email: '',
		message: '',
		loading: false,
	});

	const submit = async () => {
		const { name, email, message } = state;
		const res = await baseuri.post('/get-in-touch', {
			name,
			email,
			message,
		});

		if (res.status === 200) {
			swal({
				title: 'Message Sent!',
				text: 'You message has been sent successfully. We will response as soon as possible. Thank you.',
				icon: 'success',
			});
			setState({ ...state, name: '', email: '', message: '', loading: false });
		} else {
			swal({
				title: 'Message Failed',
				text: 'Your message was not sent succesfully. Try again, but if it persists you can send an email to us directly wi th this email info@prometasoft.org',
				icon: 'error',
			});
			setState({ ...state, loading: false });
		}
	};

	const handleFormSubmit = (e: any) => {
		e.preventDefault();
		submit();
	};

	return (
		<Layout title="Get in Touch">
			<Style>
				<Header />
				<div className="contact">
					<div>
						<p>
							<Marquee>Contact Us</Marquee>
						</p>
					</div>
					<div>
						<div>
							<div>
								<p className="in-touch">Get in touch</p>
								<h1>Tell us your idea, we&apos;ve got the skills.</h1>

								<p className="connect">Let&apos;s connect</p>

								<form onSubmit={handleFormSubmit}>
									<div className="name-email">
										<div>
											<input
												placeholder="Your name"
												name="name"
												value={state.name}
												type="text"
												onChange={(e) =>
													setState({ ...state, name: e.target.value })
												}
											/>
										</div>
										<div>
											<input
												type="email"
												placeholder="Your email"
												name="email"
												value={state.email}
												onChange={(e) =>
													setState({ ...state, email: e.target.value })
												}
												required
											/>
										</div>
									</div>
									<textarea
										placeholder="Tell us about your project, timeline, and budget"
										required
										name="message"
										id=""
										cols={30}
										rows={10}
										value={state.message}
										onChange={(e) =>
											setState({ ...state, message: e.target.value })
										}
									></textarea>
									<button disabled={state.loading} type="submit">
										Submit
									</button>
								</form>
							</div>
							<div>
								<img src="/get-in-touch.png" alt="get in touch" />
							</div>
						</div>
					</div>
				</div>
				<div className="lets-call">
					<div>
						<h2>Let&apos;s have a call</h2>
						<p>
							Not sure where to start? Schedule a free call with our consulting
							expert to get help you get started.
						</p>

						<a href="#">
							Schedule a call{' '}
							<span
								className="material-symbols-outlined"
								style={{ fontWeight: 600 }}
							>
								north_east
							</span>
						</a>
					</div>
				</div>

				<Footer />
			</Style>
		</Layout>
	);
};

export default GetInTouch;

const Style = styled.div`
	& .contact {
		position: relative;
		display: grid;
		grid-template-columns: 1fr auto;
		grid-template-rows: 1fr;

		& > div:first-child {
			position: relative;
			grid-column: 1 / -1;
			grid-row: 1 / -1;

			& p {
				font-weight: 700;
				font-size: 334.9px;
				line-height: 436.39px;
				color: #0020400a;
			}
		}

		& > div:last-child {
			position: relative;
			grid-column: 1 / -1;
			grid-row: 1 / -1;
			width: 100%;
			z-index: 1;

			& > div {
				padding: 20px;
				max-width: 1280px;
				margin-left: auto;
				margin-right: auto;
				display: grid;
				grid-template-columns: 1fr;
				grid-gap: 50px;

				@media (min-width: 768px) {
					grid-template-columns: 1fr auto;
				}

				& .in-touch {
					font-weight: 600;
					color: #002040;
					font-size: 32px;
					line-height: 41px;
					margin-top: 100px;
				}

				& h1,
				& .connect {
					font-weight: 700;
					font-size: 42px;
					line-height: 1.3;
					color: #002040;
					margin-top: 30px;

					@media (min-width: 768px) {
						font-size: 55.34px;
						line-height: 72.11px;
					}
				}

				& h1 {
					width: 80%;
				}

				& .connect {
					margin-top: 170px;
				}

				& img {
					display: none;
					width: 100%;
					height: 100%;
					margin: auto;
					mix-blend-mode: luminosity;
					transform: rotate(-180deg);

					@media (min-width: 768px) {
						display: inline;
					}
				}
			}
		}

		& form {
			& .name-email {
				display: grid;
				grid-template-columns: 1fr;
				grid-gap: 20px;

				@media (min-width: 768px) {
					grid-template-columns: 1fr 1fr;
				}
			}
			& input {
				width: 100%;
				background-color: #e4ece6;
				margin-bottom: 20px;
				font-family: 'Hanken Grotesk';
				padding-left: 10px;
				border: transparent;
				height: 60px;
				font-size: 20px;
				color: rgb(0, 64, 128);
			}
			& textarea {
				display: block;
				box-sizing: border-box;
				width: 100%;
				background-color: #e4ece6;
				padding: 24px;
				border: none;
				margin-top: 20px;
				color: rgb(0, 64, 128);
				font-family: 'Hanken Grotesk';
				font-size: 20px;
				margin-bottom: 50px;
			}

			& textarea::placeholder,
			& input::placeholder {
				color: rgba(0, 64, 128, 0.73);
			}

			& button {
				border: none;
				color: #eef4ed;
				background-color: #003060;
				display: inline-block;
				padding: 20px 60px;
				text-align: center;
				font-size: 26.7px;
				line-height: 33.37px;
				font-weight: 600;
			}
		}
	}

	& .lets-call {
		max-width: 1280px;
		margin: auto;
		margin-bottom: 100px;
		margin-top: 100px;

		& a {
			color: #004080;
			text-decoration: none;
			font-weight: 600;
			line-height: 36.43px;
			font-size: 28px;
			& span {
				color: #00cecb;
			}
		}

		& > div {
			background-color: #e8f3fe;
			color: #004080;
			text-align: center;
			font-size: 20px;
			font-weight: 600;
			width: 90%;
			padding: 20px;
			margin: auto;

			@media (min-width: 768px) {
				width: 75%;
				padding: 32px;
				& h2 {
					font-size: 55.34px;
					line-height: 72.11px;
				}
			}

			& h2 {
				color: #003060;
				font-weight: 700;
				font-size: 42px;
				line-height: 1.3;
				margin-bottom: 30px;
			}

			& p {
				width: 72%;
				margin: auto;
				margin-bottom: 75px;
			}
		}
	}
`;
