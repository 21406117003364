import axios from 'axios';

export const links = {
	linkedin: 'https://www.linkedin.com/company/prometasoft/',
	twitter: 'https://twitter.com/prometasoft',
};

export const baseuri = axios.create({
	// baseURL: 'http://localhost:3001/',
	baseURL: 'https://alpha.prometasoft.org/',
});
