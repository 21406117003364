import { styled } from 'styled-components';
import { links } from '../links';

const MobileHeader = () => {
	return (
		<Style>
			<header>
				<button id="menu-button">
					<span>
						<span className="_1"></span>
						<span className="_2"></span>
						<span className="_3"></span>
					</span>
				</button>
				<a href="/" className="logo">
					<img src="/blue-logo.svg" height="24px" alt="logo" />
					<p>
						Prometa<span className="italic">Soft</span>
					</p>
				</a>
			</header>

			<div id="sidebar">
				<div className="top">
					<button id="menu-close-button">
						<span className="material-symbols-outlined">close</span>
					</button>
					<a href="/" className="logo">
						<img src="/logo.svg" height="24px" alt="logo" />
						<p>
							Prometa<span className="italic">Soft</span>
						</p>
					</a>
				</div>
				<div className="content">
					<ul>
						<li>
							<a href="/about">About</a>
						</li>
						<li>
							<a href="/#services" className="mobile-links">
								Services
							</a>
						</li>
						<li>
							<a href="/#projects" className="mobile-links">
								Projects
							</a>
						</li>
						<li>
							<a
								href="https://blog.prometasoft.org"
								target="_blank"
								rel="noreferrer"
							>
								Blog
							</a>
						</li>
					</ul>
					{/* <button>Get in touch</button> */}
				</div>
				<div className="foot">
					<ul>
						<li>
							<a href={links.linkedin} target="_blank" rel="noreferrer">
								LinkedIn{' '}
								<span className="material-symbols-outlined">north_east</span>
							</a>
						</li>
						<li>
							<a href={links.twitter} target="_blank" rel="noreferrer">
								Twitter{' '}
								<span className="material-symbols-outlined"> north_east</span>
							</a>
						</li>
					</ul>
					<p className="copyright">Copyright &copy; 2023 PrometaSoft</p>
				</div>
			</div>
		</Style>
	);
};

export default MobileHeader;

const Style = styled.div`
	header {
		display: flex;
		padding: 20px;
		justify-content: space-between;
		align-items: center;

		@media (min-width: 768px) {
			display: none;
		}

		& .logo {
			text-decoration: none;
			display: flex;
			align-items: center;
			& p {
				margin-left: 5px;
				font-weight: 700;
				font-size: 21px;
				color: #003060;

				& .italic {
					font-style: italic;
				}
			}
		}
	}

	#menu-button {
		border: none;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		height: 35px;
		width: 35px;
		background-color: #002040;
		color: white;
		cursor: pointer;
		padding: 6px;

		& > span {
			display: block;
			width: 100%;

			& > span {
				display: block;
				height: 3px;
				background: white;
				margin-bottom: 4.5px;
				border-radius: 2px;

				&._1 {
					width: 100%;
				}
				&._2 {
					width: 82%;
				}
				&._3 {
					width: 60%;
					margin-bottom: 0;
				}
			}
		}
	}

	#sidebar {
		position: fixed;
		background-color: #002040;
		top: 0;
		left: -1000px;
		z-index: 2;
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		padding: 20px;
		color: #eef4ed;
		transition: left 400ms ease;

		&.open {
			left: 0;
		}

		& .top {
			height: 10%;
			max-height: 40px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding-bottom: 20px;

			& .logo {
				text-decoration: none;
				color: #eef4ed;
				display: flex;
				align-items: center;
				& p {
					margin-left: 5px;
					font-weight: 700;
					font-size: 21px;

					& .italic {
						font-style: italic;
					}
				}
			}

			& #menu-close-button {
				border: none;
				width: 35px;
				height: 35px;
				display: flex;
				justify-content: center;
				align-items: center;
				cursor: pointer;

				& span {
					font-weight: 600;
				}
			}
		}

		& .content {
			flex: 1;
			overflow-y: auto;

			& ul {
				margin-top: 25%;
				margin-left: 10%;

				& li {
					font-size: 24px;
					font-weight: 600;
					margin-bottom: 50px;

					& a {
						color: #eef4ed !important;
						text-decoration: none;
					}
				}
			}
		}

		& .foot {
			height: 10%;
			max-height: 100px;
			text-align: center;
			padding-top: 20px;

			& li {
				display: inline-block;

				&:first-child {
					margin-right: 20px;
				}

				& a {
					color: #eef4ed;
					text-decoration: none;
					font-size: 21px;

					& span {
						color: #f2c643;
						font-size: 16px;
						font-weight: 600;
					}
				}
			}

			& .copyright {
				margin-top: 20px;
				color: #004080;
				font-size: 12px;
				font-weight: 600;
			}
		}

		@media (min-width: 768px) {
			display: none;
		}
	}
`;
