import axios from 'axios';
import { useState } from 'react';
// import './style.css';
import { baseuri } from '../links';
import { styled } from 'styled-components';

const NotifyMe = () => {
	const [email, setEmail] = useState('');
	const [loading, setLoading] = useState(false);

	const sendToServer = async (data: any) => {
		setLoading(true);
		await axios
			.post(`${baseuri}/coming-soon`, data)
			.then(() => {
				alert('Submitted Successfully');
				setLoading(false);
				setEmail('');
			})
			.catch((e: any) => {
				console.error(e);
				setLoading(false);
			});
	};

	const handleSubmit = (e: any) => {
		e.preventDefault();
		if (email.length > 0) {
			sendToServer({ email });
		}
	};

	return (
		<Style onSubmit={handleSubmit}>
			<div className="input-wrapper">
				<input
					type="email"
					placeholder="Enter email"
					value={email}
					onChange={(e) => setEmail(e.target.value)}
				/>
				<div className="notify-wrapper">
					<button className="notify-me" type="submit">
						<span>Notify me</span>
						<span className="material-symbols-outlined">trending_flat</span>
					</button>
				</div>
			</div>
			{loading && (
				<div className="activity">
					<p>loading...</p>
				</div>
			)}
		</Style>
	);
};

export default NotifyMe;

const Style = styled.form`
	& .input-wrapper {
		position: relative;
		display: block;
		width: 100%;
		background-color: #001830;
		display: flex;
		align-items: center;
		/* border: 1px solid rgba(85, 170, 255, 0.31); */
	}

	& input {
		display: block;
		border: none;
		color: white;
		width: 100%;
		font-size: 18px;
		font-weight: 500px;
		height: 48px;
		background-color: #001830;
		padding: 5px;
		box-sizing: border-box;
	}

	& input::placeholder {
		color: #004080;
	}

	& .activity {
		color: #f2c643;
	}

	& button.notify-me {
		display: flex;
		width: 100%;
		align-items: center;
		background-color: transparent;
		border: none;
		font-size: 12px;
		font-weight: 700;
		color: #f2c643;
		cursor: pointer;
		padding: 10px 10px 10px 20px;
		border-radius: 5px;
		transition: all 400ms ease;
	}

	& button.notify-me span:first-child {
		width: max-content;
	}

	& button.notify-me:hover {
		background-color: #104579;
	}

	& button.notify-me span:last-child {
		margin-left: 15px;
	}
`;
