import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import GlobalStyles from './GlobalStyles';
import LandingPage from './components/LandingPage';
import AboutPage from './components/AboutPage';
import GetInTouch from './components/GetInTouch';

const router = createBrowserRouter([
	{
		path: '/',
		element: <LandingPage />,
	},
	{
		path: '/about',
		element: <AboutPage />,
	},
	{
		path: '/get-in-touch',
		element: <GetInTouch />,
	},
]);

function App() {
	return (
		<>
			<GlobalStyles />
			<RouterProvider router={router} />
		</>
	);
}

export default App;
