import Footer from './Footer';
import Hero from './Hero';
import Layout from './Layout';
import OurProject from './OurProject';
import Services from './Services';
import WhoWeAre from './WhoWeAre';

const LandingPage = () => {
	return (
		<Layout title="PrometaSoft">
			<Hero />
			<WhoWeAre />
			<Services />
			<OurProject />
			<Footer />
		</Layout>
	);
};

export default LandingPage;
